import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import jsPDF from 'jspdf';

const DynamicQR = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [columns, setColumns] = useState(5);

  const updateLayout = () => {
    const width = window.innerWidth;
    if (width < 600) {
      setItemsPerPage(4);
      setColumns(2);
    } else if (width < 900) {
      setItemsPerPage(6);
      setColumns(3);
    } else {
      setItemsPerPage(10);
      setColumns(5);
    }
  };

  useEffect(() => {
    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, []);

  const totalPages = Math.ceil((data?.length || 0) / itemsPerPage);

  const currentData = data
    ? data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Función para generar y descargar el PDF
  const generatePDF = () => {
    currentData.forEach((item, index) => {
      const pdf = new jsPDF();
      pdf.setFontSize(12);
      
      // Agregar campos adicionales
      pdf.text(`ID: ${item.ID}`, 10, 10);
      pdf.text(`Nombre: ${item.NOMBRE || 'N/A'}`, 10, 20); // Asegúrate de que `Nombre` exista en tu objeto
      

      // Obtener la imagen del QR
      const qrCanvas = document.getElementById(`qr-${index}`);
      if (qrCanvas) {
        const qrImage = qrCanvas.toDataURL('image/png');
        pdf.addImage(qrImage, 'PNG', 40, 50, 100, 100); // Centrar el QR
      }

      // Guardar el PDF
      pdf.save(`QR_${item.ID}.pdf`);
    });
  };

  return (
    <div>
      <h1>QR</h1>
      {data && data.length > 0 ? (
        <>
          <div style={{ display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`, gap: '20px' }}>
            {currentData.map((item, index) => (
              <div key={index} style={{ textAlign: 'center' }}>
                <p>{JSON.stringify(item.ID)}</p>
                <QRCodeCanvas id={`qr-${index}`} value={JSON.stringify(item)} /> {/* Genera el QR */}
              </div>
            ))}
          </div>

          {/* Botón para generar los PDFs */}
          <button className="glow-on-hover" onClick={generatePDF}>
            Descargar PDFs
          </button>

          {/* Controles de paginación */}
          <div className="pagination-container">
            <button className="glow-on-hover" onClick={goToPreviousPage} disabled={currentPage === 1}>
              Anterior
            </button>
            <span style={{ margin: '0 10px' }}>
              Página {currentPage} de {totalPages}
            </span>
            <button className="glow-on-hover" onClick={goToNextPage} disabled={currentPage === totalPages}>
              Siguiente
            </button>
          </div>
        </>
      ) : (
        <p>No hay datos disponibles para generar códigos QR.</p>
      )}
    </div>
  );
};

export default DynamicQR;

