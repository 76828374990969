import React, { useState } from 'react';
import FileInput from './FileInput';
import DynamicQR from './DynamicQR';
import QRCodeReader from './QRCodeReader';
import ExcelExport from './ExcelExport';
import './App.css';
function App() {
  const [data, setData] = useState(null); // Estado para almacenar los datos
  const [showQR, setShowQR] = useState(true); // Estado para controlar la visibilidad de los QR
  const [scanMessage, setScanMessage] = useState(''); // Estado para almacenar el mensaje de escaneo
  const [isQRReaderActive, setIsQRReaderActive] = useState(true); // Estado para controlar si el lector QR está activo
  const [scannedCodes, setScannedCodes] = useState(new Set()); // Estado para rastrear códigos ya leídos

  // Manejar el escaneo de QR
  const handleScan = (result) => {
    if (!isQRReaderActive) return; // No hacer nada si el lector está desactivado

    console.log('QR escaneado:', result);

    if (!result) {
      console.error('Resultado del escaneo vacío');
      return;
    }

    let scannedObject;
    try {
      scannedObject = JSON.parse(result);
    } catch (error) {
      console.error('Error al parsear el resultado:', error);
      return;
    }

    // Validar si el QR ya fue leído
    if (scannedCodes.has(scannedObject.ID)) {
      setScanMessage(`El código ${scannedObject.ID} ya fue leído.`);
      setTimeout(() => setScanMessage(''), 3000); // Limpiar mensaje después de 3 segundos
      return;
    }

    // Marcar el código como leído
    setScannedCodes((prev) => new Set(prev).add(scannedObject.ID));

    // Actualizar la hora de ingreso en los datos
    const scanTime = new Date().toLocaleString();
    const updatedData = data.map((item) =>
      item.ID === scannedObject.ID
        ? { ...item, 'HORA DE INGRESO': scanTime }
        : item
    );

    setData(updatedData);

    // Mostrar mensaje de escaneo exitoso
    setScanMessage(`Escaneo exitoso: ${scannedObject.ID} a las ${scanTime}`);
    setTimeout(() => setScanMessage(''), 3000); // Limpiar mensaje después de 3 segundos
  };

  // Función para alternar la visibilidad de los QR
  const toggleQRVisibility = () => {
    setShowQR(!showQR);
  };

  // Función para activar/desactivar el lector QR
  const toggleQRReader = () => {
    setIsQRReaderActive(!isQRReaderActive);
  };

  return (
    <div className="App">
      <h1>Lector Archivo</h1>
      <FileInput setData={setData} />
      
      {/* Botón para ocultar/mostrar QR generados */}
      <button className="glow-on-hover" onClick={toggleQRVisibility}>
        {showQR ? 'Ocultar QR' : 'Mostrar QR'}
      </button>

      {/* Botón para activar/desactivar el lector QR */}
      <button className="glow-on-hover" onClick={toggleQRReader}>
        {isQRReaderActive ? 'Desactivar Lector QR' : 'Activar Lector QR'}
      </button>

      {/* Mostrar DynamicQR solo si showQR es true */}
      {showQR && <DynamicQR data={data} />}

      {/* Componente para escanear códigos QR */}
      {isQRReaderActive && (
        <QRCodeReader
          handleScan={handleScan}
          scannedCodes={scannedCodes}
        />
      )}

      {/* Mensaje de escaneo */}
      {scanMessage && <p style={{ color: 'green' }}>{scanMessage}</p>}

      <br />
      {data && <ExcelExport data={data} fileName="listado oficial" />}
    </div>
  );
}

export default App;
