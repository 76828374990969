import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

const QRCodeReader = ({ handleScan }) => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [qrPosition, setQrPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [scannedCodes, setScannedCodes] = useState(new Set()); // Almacena los códigos QR leídos
  const [duplicateMessage, setDuplicateMessage] = useState(''); // Mensaje para QR duplicados

  useEffect(() => {
    const qrScanner = new QrScanner(
      videoRef.current,
      (result) => {
        setLoading(false);

        if (scannedCodes.has(result)) {
          // Si el código ya fue leído, mostramos un mensaje
          setDuplicateMessage('Este código ya fue leído');
          setTimeout(() => setDuplicateMessage(''), 3000); // Borra el mensaje después de 3 segundos
        } else {
          // Si es un nuevo código, lo agregamos al conjunto
          setScannedCodes((prev) => new Set(prev).add(result));
          handleScan(result); // Llama a la función de escaneo proporcionada
        }
      },
      (error) => {
        console.error('Error al iniciar el escáner:', error);
        setError('escanear un qr valido.');
      }
    );

    qrScanner
      .start()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al acceder a la cámara:', error);
        setLoading(false);
        setError('Error al acceder a la cámara. Asegúrate de que tienes permisos.');
      });

    return () => {
      qrScanner.stop(); // Detiene el escáner cuando se desmonta el componente
    };
  }, [handleScan, scannedCodes]);

  return (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      {loading && <p>Cargando escáner...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {duplicateMessage && <p style={{ color: 'orange', fontWeight: 'bold' }}>{duplicateMessage}</p>}
      <video
        ref={videoRef}
        style={{
          width: '80%',
          height: 'auto',
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      />
      <p>Apunta el escáner hacia un código QR para escanearlo.</p>

      {/* Marco animado para el QR detectado */}
      {qrPosition.width > 0 && (
        <div
          style={{
            position: 'absolute',
            top: `${qrPosition.top}px`,
            left: `${qrPosition.left}px`,
            width: `${qrPosition.width}px`,
            height: `${qrPosition.height}px`,
            border: '2px solid green',
            borderRadius: '8px',
            animation: 'pulse 1s infinite',
          }}
        />
      )}

      {/* Estilo CSS para la animación */}
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 0.5;
            }
            50% {
              transform: scale(1.1);
              opacity: 1;
            }
            100% {
              transform: scale(1);
              opacity: 0.5;
            }
          }
        `}
      </style>
    </div>
  );
};

export default QRCodeReader;
