import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';

function FileInput({ setData }) {
  // Función para cargar el archivo .xlsx
  const loadFile = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setData(sheetData); // Actualizamos los datos
    };

    reader.readAsBinaryString(file);
  };

  // Cargar automáticamente el archivo desde la carpeta public
  useEffect(() => {
    const filePath = `${process.env.PUBLIC_URL}/data.xlsx`;

    fetch(filePath)
      .then(response => response.arrayBuffer())
      .then((data) => {
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);

        setData(sheetData);
      })
      .catch(error => {
        console.error('Error al cargar el archivo desde public:', error);
      });
  }, [setData]);

  // Función para manejar la carga manual del archivo
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      loadFile(file); // Cargar el archivo manualmente
    }
  };

  return (
    <div>
      <p>El archivo por defecto se ha cargado desde public/data.xlsx</p>
      <label htmlFor="file-upload" className="file-upload-button glow-on-hover">
        Seleccionar archivo para actualizar
      </label>
      <input id="file-upload" type="file" onChange={handleFileUpload} />
    </div>
  );
}

export default FileInput;
